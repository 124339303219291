/** @jsx jsx */
import { jsx } from 'theme-ui'

const Quote = (props) => {
    const {
        quote,
        author,
        author_title,
        color,
    } = props

    return (
        <div sx={{ 
            my: 5, mx: 'auto', maxWidth: '700px',
            textAlign: 'center',
        }}>
            <div sx={{
                fontSize: 4, fontFamily: 'heading',
                lineHeight: '1.7', color: color,
                position: 'relative',
            }}>
                {quote}
                <span sx={{
                    position: 'absolute', top: '-20px', left: '-50px', lineHeight: 1,
                    opacity: '.1', color: color, fontSize: "200px", fontWeight: 'bold',
                }}>«</span>
            </div>
            <div sx={{
                color: 'grey', fontSize: 2, mt: 3,
            }}>
                {author} 
                <span sx={{
                    fontStyle: 'italic', textTransform: "uppercase",
                    ml: 2, fontSize: 1,
                }}>{author_title}</span>
            </div>
        </div>
    )
}

export default Quote