/** @jsx jsx */
import { jsx } from 'theme-ui'

const Video = (props) => {
    const {
        // caption,
        code,
        // color
    } = props

    return (
        <div sx={{
            my: 4, mx: 'auto', textAlign: "center",            
        }}>
            <div 
                dangerouslySetInnerHTML={{ __html: code }}
                sx={{
                    position: 'relative',
                    width: '100%', height: '0', pb: '56.25%',
                    borderRadius: 2, overflow: "hidden",
                    boxShadow: 0,
                    iframe: {
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                    }
                }}
            />
            {/*<div sx={{
                color: 'grey', mt: 3,
            }}>
                {caption}
            </div>*/}
        </div>
    )
}

export default Video