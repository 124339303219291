/** @jsx jsx */
import { jsx } from 'theme-ui'

const Milestones = (props) => {
    const {
        events,
        color,
    } = props

    return (
        <div sx={{ 
            p: 4, mb: 4, borderRadius: 2, 
            bg: 'background', boxShadow: 0,
        }}>
            <h2 sx={{mt: 0}}>Notre histoire</h2>
            <div>
                {events.map( event => {
                    const {
                        name,
                        description
                    } = event

                    return (
                        <div
                            key={name}
                        >
                            <h3 sx={{
                                mb: 2, mt: 2,
                                color: color,
                            }}>
                                {name}
                            </h3>
                            <p sx={{
                                my: 0, py: 2, pl: 3, ml: 3, 
                                fontSize: 1,
                                borderLeft: `1px solid ${color}`,
                            }}>
                                {description}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Milestones