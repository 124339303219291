// import React from 'react'
import { graphql, Link } from 'gatsby'

import { MdVerifiedUser } from 'react-icons/md'

import Layout from '../components/layout'
import SEO from "../components/seo"

/** @jsx jsx */
import { Container, jsx } from 'theme-ui'

import SingleListing from '../components/singleListing'

const Listing = (props) => {
    const entreprise = props.data.prismicEntreprise.data
    // console.log("Entreprise", entreprise)

    const tags = props.data.prismicEntreprise.tags
    const category = props.pageContext.category

    const pitch = entreprise.pitch.text
    const name = entreprise.record.text
    const url = entreprise.website.url
    const telephone = entreprise.phone_number ? entreprise.phone_number.replace(/\s+/g, "") : null
    const email = entreprise.contact_email
    const logo = entreprise.logo.fixed
    const color = entreprise.maincolor 
    const locations = entreprise.locations
    const cover = entreprise.cover.fluid
    const verified = entreprise.verified
    const draft = entreprise.draft

    const blocks = entreprise.body

    // console.log("ENTREPRISE", props.data.prismicEntreprise)

    return (
        <Layout>
            <SEO title={name} description={pitch} article={true} noindex={draft} />

            <Container>
              <SingleListing 
                entreprise={entreprise}
                pitch={pitch}
                name={name}
                url={url}
                telephone={telephone}
                email={email}
                logo={logo}
                cover={cover}
                color={color}
                locations={locations}
                blocks={blocks}
                verified={verified}
                tags={tags.filter( tag => tag !== category )}
              />
            </Container>

            {!blocks.length > 0 &&  
              <div>
                <p sx={{
                  textAlign: 'center',
                  color: 'grey',
                  fontSize: 1,
                }}><MdVerifiedUser sx={{mb: '-3px', color: 'green'}} /> Cet établissement vous appartient ? <Link to="/referencement" sx={{color: "primary"}}>Contactez-nous</Link> pour authentifier et gérer ce listing.</p>
              </div>
            }
        </Layout>
    )
}

export default Listing

export const SingleListingQuery = graphql`
  query SingleListing($uid: String!) {
    prismicEntreprise(uid: { eq: $uid }) {
      uid
      ...ListingFragment
    }
  }
` 