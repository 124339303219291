/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

import { GiBullseye } from 'react-icons/gi'
import { IoMdSad, IoMdHappy } from 'react-icons/io'

const Customers = (props) => {
    const {
        intro_text,
        customer_types,
        color,
    } = props

    return (
        <div sx={{ 
            p: 4, mb: 4, borderRadius: 2, 
            bg: 'background', boxShadow: 0,
        }}>
            <h2 sx={{mt: 0}}>Nous répondons à vos besoins</h2>
            <p>{intro_text}</p>
            <Grid
                gap={3}
                columns={[ 1, 1, 1 ]}
            >
                {customer_types.map( customer => {
                    const {
                        name,
                        jobs,
                        pains,
                        gains,
                    } = customer

                    return (
                        <div 
                            key={name}
                            sx={{
                                svg: {
                                    mb: '-2px', color: color,
                                },
                                p: {
                                    padding: 2, mb: 1,
                                    borderRadius: 0,
                                    bg: 'muted',
                                }
                            }}
                        >
                            <h3 sx={{
                                mb: 2,
                            }}>
                                {name}
                            </h3>
                            <p sx={{
                                fontSize: 1, mt: 0, mb: 1,
                            }}>
                                <GiBullseye /> {jobs}
                            </p>
                            <p sx={{
                                fontSize: 1, mt: 0, mb: 1,
                            }}>
                                <IoMdSad /> {pains}
                            </p>
                            <p sx={{
                                fontSize: 1, mt: 0, mb: 1,
                            }}>
                                <IoMdHappy /> {gains}
                            </p>
                        </div>
                    )
                })}
            </Grid>
        </div>
    )
}

export default Customers