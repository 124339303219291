// import React from 'react'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const Button = (props) => {
    const {
        link,
        title,
        follow,
        blank,
        color,
        children,
    } = props

    return (
        <a  href={link}
            title={title}
            rel={!follow && 'nofollow noreferrer noopener'}
            target={blank ? '_blank' : null}
            sx={{
                display: 'inline-block',
                px: 3,
                py: 3,
                bg: color || 'primary',
                borderRadius: 0,
                mr: 3,
                color: 'background',
                textDecoration: 'none',

                svg: {
                    mb: '-2px',
                    mr: 1,
                },

                '&:hover': {
                    bg: 'secondary',
                }
            }}
            {...props}
        >
            {children}
        </a>
    )
}

export default Button