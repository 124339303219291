import Img from "gatsby-image"
import { GiHidden } from "react-icons/gi"

/** @jsx jsx */
import { jsx } from 'theme-ui'

const Gallery = (props) => {
    const items = props.items
    // console.log(props)

    return (
        <div sx={{ 
            mb: 4, borderRadius: 2, overflow: "hidden",
            bg: 'background', boxShadow: 0,
        }}>
            {items.map( item => {
                return (
                    <Img key={item.image.fluid.src} fluid={item.image.fluid} />
                )
            })}
        </div>
    )
}

export default Gallery