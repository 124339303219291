// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

import { MdAssignmentInd } from "react-icons/md"
import { FaLinkedin } from "react-icons/fa"

const Team = (props) => {
    const {
        intro_text,
        members,
        color,
    } = props

    return (
        <div sx={{ 
            p: 4, mb: 4, borderRadius: 2, 
            bg: 'background', boxShadow: 0,
        }}>
            <h2 sx={{
                mt: 0, mb: 4, textAlign: "center",
            }}>Notre équipe dirigeante</h2>
            <p>{intro_text}</p>

            <Grid
                gap={5}
                columns={[ 1, 2, 2 ]}
            >
                {members.map( member => {
                    const {
                        name,
                        role, 
                        picture,
                        cv_link,
                        bio,
                    } = member

                    return (
                        <div 
                            key={name}
                            sx={{
                                textAlign: 'center',
                                svg: {
                                    mb: '-2px', color: color,
                                }
                        }}>
                            {picture && <Img 
                                fixed={picture} 
                                sx={{
                                    borderRadius: 3,
                                }}
                            />}

                            <h3 sx={{
                                mb: 2,
                            }}>
                                {name}
                            </h3>

                            <p sx={{
                                fontSize: 1, mt: 0, mb: 1,
                            }}>
                                <MdAssignmentInd /> {role}
                            </p>

                            {cv_link && <a href={cv_link}
                                target="blank"
                                rel='noopener noreferrer nofollow'
                                sx={{
                                    textDecoration: 'none',
                                    fontSize: 1, color: 'text',
                                }}
                            ><FaLinkedin /> CV Linkedin</a>}

                            {bio && <div 
                                sx={{
                                    bg: 'muted', color: 'grey', maxWidth: '350px', m: '0 auto',
                                    px: 4, py: 3, mt: 3, borderRadius: 1,
                                    'p, ul': {
                                        textAlign: 'left', fontSize: 1, mt: 0, mb: 1,
                                    }
                                }}
                                dangerouslySetInnerHTML={{ __html: bio }}
                            />}
                        </div>
                    )
                })}
            </Grid>
        </div>
    )
}

export default Team