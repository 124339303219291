// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx, Grid } from 'theme-ui'

const Products = (props) => {
    const {
        intro_text,
        products, 
        color,
    } = props

    return (
        <div sx={{ 
            p: 4, mb: 4, 
            borderRadius: 2, 
            bg: 'background', boxShadow: 0,
        }}>
            <h2 sx={{mt: 0}}>Nos services</h2>
            <p>{intro_text}</p>
            <Grid
                gap={4}
                columns={products.length === 2 || products.length === 4 ? [1, 2, 2] : [1, 2, 3]}
            >
                {products.map( product => {
                    const {
                        image,
                        name,
                        description,
                        link_title,
                        link_url,
                    } = product

                    return (
                        <div
                            key={name}
                        >
                            {image && <Img sx={{borderRadius: 0}} fluid={image} />}
                            <h3 sx={{
                                mb: 2,
                            }}>
                                {name}
                            </h3>
                            {description && <p sx={{
                                fontSize: 1, mt: 0, mb: 1,
                            }}>
                                {description}
                            </p>}
                            {link_url && <a href={link_url} 
                                title={`${link_title} sur ${name}`} 
                                target='blank'
                                rel='nofollow noreferrer noopener'
                                sx={{
                                    fontSize: 1,
                                    color: color || 'primary',
                                    textDecoration: 'none',
                                }}
                            >
                                {link_title}
                            </a>}
                        </div>
                    )
                })}
            </Grid>
        </div>
    )
}

export default Products